import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Base from "./components/base/Base";
import EmployeeManagement from "./components/EmployeeManagement";

import "./App.css";
import PrivateRoutes from "./Routes/PrivateRoutes";

function App() {
  var jwt = document.cookie
    .split("; ")
    .find((row) => row.startsWith("C-X-ssoJwt="));
  if (jwt === undefined) {
    window.location = process.env.REACT_APP_SSO_LOGIN_URL;
  }
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
         <PrivateRoutes>
          <Route exact path="/home">
            <Base />
          </Route>
          <Route exact path="/report">
            <Base />
          </Route>
          <Route exact path="/profile">
            <Base />
          </Route>
          <Route exact path="/invoices-employees">
            <Base />
          </Route>
          <Route exact path="/employee-management">
            <Base />
          </Route>
          {/* <Route exact path = "/clickup-data">
            <Base/>
          </Route> */}
          <Route exact path = "/centralized-data">
            <Base/>
          </Route>
          <Route exact path = "/estimate-data">
            <Base/>
          </Route>
          <Route exact path = "/clickup-form-data">
            <Base/>
          </Route>
          <Route exact path = "/marketing-data">
            <Base/>
          </Route>
          <Route exact path = "/marketing-data-upload">
            <Base/>
          </Route>
           
          <Route exact path = "/biometric-data">
            <Base/>
          </Route>

          </PrivateRoutes>
          <Route exact path="/logout">
            <Logout />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
